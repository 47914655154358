export default {
  navTab: {
    title: 'BLUE-CONNECT',
    tab1: 'Start',
    tab2: 'WHO WE ARE',
    tab3: 'WORK WITH US',
    tab4: 'CONTACT US'
  },
  homeTop: {
    h3Title: 'OMNICHANNEL CONTACT CENTER',
    h3Title1: 'Professional and Technical services',
    desc: 'BLUE-CONNECT TECHNOLOGY LIMITED is a technology company based in Lagos, Nigeria, to provide customers with multi-dimensional and all-round professional and technical services, with an advanced and excellent technology R&D team'
  },
  homeBottom: {
    title: 'What we offer',
    menu1: 'EFFICIENCY',
    menuDesc1: 'We operate with a management model that implements a continuous improvement process in your operation and our staff, supported by Work Force Management, Training, Business Intelligence and the Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.We manage interactions with your customers on a multi-channel platform that allows you to contact your customers appropriately, by the means they seek and adjust to their needs.',
    menu3: 'EXPERT STAFF',
    menuDesc3: 'We have the attraction, recruitment and training of qualified personnel, as well as an organizational culture program focused on the well-being of our collaborators to promote their professional development.',
    menu4: 'THE BEST SOLUTION OF',
    menu4br: 'CONTACT',
    menuDesc4: 'We analyze and optimize your processes to be more efficient and help make your company profitable'
  },
  bottom: {
    title: 'BLUE-CONNECT TECHNOLOGY LIMITED',
    email: 'Finance.BLUECONNECT@outlook.com',
    facebook: 'Facebook: Dino Outsource',
    about: 'ABOUT US',
    joinUs: 'WORK WITH US',
    contact: 'CONTACT US',
    privacyTitle: 'quick links',
    privacy: 'Notice of Privacy'
  },
  aboutUs: {
    title: 'Company Profile',
    p1: 'Blue Connect is a technology company that specializes in providing multi-dimensional and all-around professional and technical services to its customers. With an advanced and excellent technology R&D team, Blue Connect is able to offer innovative solutions to businesses across a variety of industries. ',
    p2: `The company's business scope includes software development, cloud computing, data analytics, and cybersecurity. Blue Connect's software development services cover everything from custom software development to mobile application development. `,
    p4: `In addition, the company offers cloud computing services that allow businesses to store and access their data from anywhere in the world. Blue Connect's data analytics services help businesses to make sense of their data and use it to make informed decisions. Finally, the company's cybersecurity services help businesses to protect their sensitive data from cyber attacks. `,
    p5: 'At Blue Connect, employees have the opportunity to work with cutting-edge technology and be a part of a team that is dedicated to innovation. The company offers competitive salaries, flexible work arrangements, and a supportive work environment. '
  },
  joinUs: {
    title: 'Recruitment',
    subTitle: 'Programme Engineer',
    descTitle: 'Job Description',
    descSubTitle: '【Programme Engineer】',
    content1: 'Due to expansion, an IMPORTANT COMPANY IN THE FINTECH BRANCH is en-',
    content2: 'we invite you to join our great team of telephone advisors!!',
    section1Title: 'We offer:',
    section1Desc1: 'Base salary 50k ~ 55K NGN',
    section1Desc2: 'Incentives for productivity from 20k ~ 100K NGN',
    section1Desc3: 'Punctuality Bonus 5000 NGN',
    section1Desc4: 'Referral Bonus 350 NGN',
    section1Desc5: 'Benefits of law from the first day',
    section1Desc6: 'Paid Training',
    section1Desc7: 'Free Coffee Break Service',
    section1Desc8: 'Dining room with soda machine (Terrace with a view of Chapultepec Castle)',
    section1Desc9: 'Game Rooms',
    section1Desc10: 'Modern Installations',
    section2Title: 'Requirements:',
    section2Desc1: '18 to 45 years old',
    section2Desc2: 'Secondary completed',
    section2Desc3: 'Documentation in order',
    section2Desc4: "No experience needed, we'll train you!!",
    section2Desc5: 'Shift from 9 to 6 from Monday to Sunday with rest during the week',
    vitae: 'How to submit (apply) your Curriculum Vitae:',
    vitaeGray: 'Application via Email',
    vitaeOrange: '“Finance.BLUECONNECT@outlook.com”'
  },
  contactUs: {
    title: 'CONTACT US',
    p1: 'Company Address：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'from Mexico, CDMX',
    p4: 'Email:',
    p5: 'Finance.BLUECONNECT@outlook.com',
    p6: 'Facebook：',
    p7: 'Dino Outsource'
  }
}
